import { BaseQueryParams } from "../baseQuery";

export const packageService = BaseQueryParams("packageService", [
  "ACCEPT_ORDER",
]).injectEndpoints({
  endpoints: (builder) => ({
    getPackages: builder.query({
      query: () => ({
        url: "/migrations/service/steps/type-service",
        method: "GET",
      }),
    }),

    savePackage: builder.mutation({
      query: (body) => ({
        url: "/migrations/service/steps/type-service",
        method: "POST",
        body,
      }),
    }),

    getSourceStore: builder.query({
      query: () => ({
        url: "/migrations/service/steps/source-store",
        method: "GET",
      }),
    }),

    saveSourceStore: builder.mutation({
      query: (body) => ({
        url: "/migrations/service/steps/source-store",
        method: "POST",
        body,
      }),
    }),

    saveSourceStoreAccess: builder.mutation({
      query: (body) => ({
        url: "/migrations/service/steps/source-store/access",
        method: "POST",
        body,
      }),
    }),

    getTargetStore: builder.query({
      query: () => ({
        url: "/migrations/service/steps/target-store",
        method: "GET",
      }),
    }),

    saveTargetStore: builder.mutation({
      query: (body) => ({
        url: "/migrations/service/steps/target-store",
        method: "POST",
        body,
      }),
    }),

    saveTargetStoreAccess: builder.mutation({
      query: (body) => ({
        url: "/migrations/service/steps/target-store/access",
        method: "POST",
        body,
      }),
    }),

    getAcceptOrder: builder.query({
      query: () => ({
        url: "/migrations/service/steps/accept-order",
        method: "GET",
      }),

      providesTags: () => ["ACCEPT_ORDER"],
    }),

    saveAcceptOrder: builder.mutation({
      query: (body) => ({
        url: "/migrations/service/steps/accept-order",
        method: "POST",
        body,
      }),
    }),

    uploadFile: builder.mutation({
      query: (body) => ({
        url: "/migrations/service/steps/accept-order/file",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["ACCEPT_ORDER"],
    }),

    deleteFile: builder.mutation({
      query: (id) => ({
        url: `/migrations/service/steps/accept-order/file/${id}`,
        method: "Delete",
      }),

      invalidatesTags: () => ["ACCEPT_ORDER"],
    }),
  }),
});

export const {
  useGetPackagesQuery,
  useSavePackageMutation,
  useGetSourceStoreQuery,
  useSaveSourceStoreMutation,
  useSaveSourceStoreAccessMutation,
  useGetTargetStoreQuery,
  useSaveTargetStoreMutation,
  useSaveTargetStoreAccessMutation,
  useGetAcceptOrderQuery,
  useSaveAcceptOrderMutation,
  useUploadFileMutation,
  useDeleteFileMutation,
} = packageService;
